<template>
  <div class="rule-index">
    <subnav currPage="footerRule" showPrevious></subnav>
    <v-container class="my-5">
      <div class="rule-card py-4">
        <div class="rule-title font-w-4 rem-6 rem-sm-9 py-1 mb-4 mx-auto text-center" :class="$store.state.nowWidth > 600 ? 'lightPrimary2 black--text' : 'darkGrey white--text'">{{ $t('footerRule') }}</div>
        <div class="font-w-4 rem-1 rem-sm-3 lightGrey2--text text-center mb-6 mb-sm-5">{{ $t('ruleWarning') }}</div>

        <div
          class="rule-content font-w-4 px-12 px-sm-11 pt-sm-0 pb-6 d-flex align-center"
          :class="[$store.state.nowWidth > 600 ? 'white--text rem-2' : 'black--text']"
          v-for="(item, i) in $t('ruleContent')"
          :key="i"
        >
          {{ item }}
        </div>

        <div
          class="deposit-btn success white--text rem-4 font-w-7 pa-1 border-radius-3 mx-auto mt-3 can-click"
          @click="$router.push({name: 'Deposit', params: {lang: $store.state.locale, interestToken: $store.state.interestTokens[0].token, depositToken: $store.state.depositTokens[0].token}})"
        >
          {{ $t('depositImmediately') }}
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
import subnav from '@/components/subnav.vue'
export default {
  components: {
    subnav
  },
}
</script>
<style lang="scss">
.rule-index {
  @include dai_vuetify_sm_min {
    max-width: 420px;
    margin: 0 auto;
  }
  .rule-card {
    overflow: hidden;
    background: linear-gradient(93.36deg, rgba(99, 99, 99, 0.8) 1.1%, rgba(54, 54, 54, 0.8) 96.27%);
    box-shadow: 0px 4.7px 4.7px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(3.5px);
    border-radius: 6px;
    @include dai_vuetify_sm {
      background: #FBFBFB;
      border-radius: 9px;
    }
    .rule-title {
      width: 207px;
      border-radius: 27px;
    }
    .rule-content {
      .index-text {
        font-size: 105px;
        letter-spacing: 0.18em;
        line-height: 2rem;
        padding-bottom: 2rem;
      }
    }
    .bottom-line {
      border-bottom: 2px #F1F1F1 solid;
    }
    .deposit-btn {
      width: fit-content;
    }
  }
}
</style>